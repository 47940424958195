
import { MainLayout } from "@/layouts";
import routes from "./routes.js";

export default {
    name: "Demos",
    path: "/demos",
    component: MainLayout,
    children: routes
};
