export const DEMO_NAV_IDS = {
	DEMOS: "sb-nav-demos",
	DEMOS_GRIDS: "sb-nav-demos-grids",
        DEMOS_BASIC_GRID_DEMO: "sb-nav-demos-basic-grid",
        DEMOS_ACTION_GRID_DEMO: "sb-nav-demos-action-grid",
        DEMOS_EDITABLE_GRID_DEMO: "sb-nav-demos-editable-grid",
        DEMOS_DRAG_DROP_GRID_DEMO: "sb-nav-demos-drag-drop-grid",
        DEMOS_DRAG_DROP_MASTER_DETAIL_DEMO: "sb-nav-demos-drag-drop-master-detail",
        DEMOS_TREE_LIST_DEMO: "sb-nav-demos-tree-list",
        DEMOS_FAUX_GRID_DEMO: "sb-nav-demos-faux-grid",
	DEMOS_DOCUMENTS: "sb-nav-demos-docs",
        DEMOS_DOC_UTIL: "sb-nav-demos-doc-uitl",
        DEMOS_RQ_DOC_EDITOR: "sb-nav-demos-rq-doc-editor",
        DEMOS_LIST_STYLE_FORMATS: "sb-nav-demos-list-styles",
        DEMOS_DEVX_RICHEDIT: "sb-nav-demos-devx-richedit",
        DEMOS_WOPI_EDITOR: "sb-nav-demos-wopi-editor",
        DEMOS_PDF_VIEWER: "sb-nav-demos-pdf-viewer",
        DEMOS_HTML_EDITORS: "sb-nav-demos-html-editors",
    DEMOS_FORMS: "sb-nav-demos-forms",
        DEMOS_FORM_EDITORS: "sb-nav-demos-form-editors",
        DEMOS_VALIDATION: "sb-nav-demos-validation",
        DEMOS_EMAIL_TAG_BOX: "sb-nav-demos-email-tag-box",
    DEMOS_LISTS: "sb-nav-demos-lists",
        DEMOS_LIST_MANAGER: "sb-nav-demos-list-manager",
        DEMOS_SELECTION_LIST: "sb-nav-demos-selection-list",
    DEMOS_CLAUSES: "sb-nav-demos-clauses",
        DEMOS_CLAUSE_LIST_MANAGER: "sb-nav-demos-clause-list-manager",
        DEMOS_DRAG_DROP_DEMO: "sb-nav-demos-drag-drop-demo",
    DEMOS_VUE_3: "sb-nav-demos-vue-3",
        DEMOS_RQ_BUSY_COMPOSABLE: "sb-nav-demos-rq-busy-composable",
    DEMOS_WIDGETS: "sb-nav-demos-widgets",
        DEMOS_CIRCLE_GAUGE: "sb-nav-demos-circle-gauge",
	DEMOS_TREEVIEW: "sb-nav-demos-treeview",
    DEMOS_IMAGE_PREVIEW: "sb-nav-demos-image-preview",
    DEMOS_OFFCANVAS: "sb-nav-demos-offcanvas",
    DEMOS_REPORT_VIEWER: "sb-nav-demos-report-viewer",
    DEMOS_WIZARD_COMPONENT: "sb-nav-demos-wizard-component",
    DEMOS_MASTER_DETAIL_VIEW: "sb-nav-demos-master-detail-view",
    DEMOS_API_TESTER: "sb-nav-demos-api-tester",
    DEMOS_TOAST_TESTER: "sb-nav-demos-toast-tester",
    DEMOS_INTEGRATION_TESTER: "sb-nav-demos-integration-tester",
    DEMOS_DIALOGS: "sb-nav-demos-dialogs",
};

export const DemoNavItems = {
    id: DEMO_NAV_IDS.DEMOS, name: "demos", label: "Demos", path: "/demos", icon: "fas fa-code", matchRoutePrefix: "demos",
    children: [
        { id: DEMO_NAV_IDS.DEMOS_GRIDS, name: "demos:grids", label: "Grids", path: "/demos/grids",
            children: [
                { id: DEMO_NAV_IDS.DEMOS_BASIC_GRID_DEMO, name: "demos:grids:basic-grid", label: "Basic Grid", path: "/demos/grids/basic-grid" },
                { id: DEMO_NAV_IDS.DEMOS_ACTION_GRID_DEMO, name: "demos:grids:action-grid", label: "Action Grid", path: "/demos/grids/action-grid" },
                { id: DEMO_NAV_IDS.DEMOS_EDITABLE_GRID_DEMO, name: "demos:grids:editable-grid", label: "Editable Grid", path: "/demos/grids/editable-grid" },
                { id: DEMO_NAV_IDS.DEMOS_DRAG_DROP_GRID_DEMO, name: "demos:grids:drag-drop-grid", label: "Drag & Drop Grid", path: "/demos/grids/drag-drop-grid" },
                { id: DEMO_NAV_IDS.DEMOS_DRAG_DROP_MASTER_DETAIL_DEMO, name: "demos:grids:drag-drop-master-detail-grid", label: "Drag/Drop Master-Detail", path: "/demos/grids/drag-drop-master-detail-grid" },
                { id: DEMO_NAV_IDS.DEMOS_TREE_LIST_DEMO, name: "demos:grids:tree-list", label: "Tree List", path: "/demos/grids/tree-list" },
                { id: DEMO_NAV_IDS.DEMOS_FAUX_GRID_DEMO, name: "demos:grids:faux-grid", label: "Faux Grid", path: "/demos/grids/faux-grid" }
            ]
        },
        { id: DEMO_NAV_IDS.DEMOS_DOCUMENTS, name: "demos:docs", label: "Documents", path: "/demos/documents",
            children: [
                { id: DEMO_NAV_IDS.DEMOS_DOC_UTIL, name: "demos:docs:doc-util", label: "Document Utilities", path: "/demos/documents/utilities" },
                { id: DEMO_NAV_IDS.DEMOS_RQ_DOC_EDITOR, name: "demos:docs:rq-doc-editor", label: "RQ Document Editor", path: "/demos/documents/rq-document-editor" },
                { id: DEMO_NAV_IDS.DEMOS_LIST_STYLE_FORMATS, name: "demos:docs:list-styles", label: "List Style Formats", path: "/demos/documents/list-styles" },
                { id: DEMO_NAV_IDS.DEMOS_PDF_VIEWER, name: "demos:docs:pdf-viewer", label: "PDF Viewer", path: "/demos/documents/pdf-viewer" },
                { id: DEMO_NAV_IDS.DEMOS_HTML_EDITORS, name: "demos:docs:html-editors", label: "Html Editors", path: "/demos/documents/html-editors" },
            ]
        },
        { id: DEMO_NAV_IDS.DEMOS_FORMS, name: "demos:forms", label: "Forms", path: "/demos/forms",
            children: [
                { id: DEMO_NAV_IDS.DEMOS_FORM_EDITORS, name: "demos:forms:form-editors", label: "Form Editors", path: "/demos/forms/form-editors" },
                { id: DEMO_NAV_IDS.DEMOS_VALIDATION, name: "demos:forms:validation", label: "Validation", path: "/demos/forms/validation" },
                { id: DEMO_NAV_IDS.DEMOS_EMAIL_TAG_BOX, name: "demos:forms:email-tag-box", label: "Email Tag Box", path: "/demos/forms/email-tag-box" }
            ]
        },
        { id: DEMO_NAV_IDS.DEMOS_LIST_BOXES, name: "demos:list-boxes", label: "List Boxes", path: "/demos/list-boxes",
            children: [
                { id: DEMO_NAV_IDS.DEMOS_LIST_MANAGER, name: "demos:list-boxes:list-manager", label: "List Manager", path: "/demos/list-boxes/list-manager" },
                { id: DEMO_NAV_IDS.DEMOS_SELECTION_LIST, name: "demos:list-boxes:selection-list", label: "Selection List Box", path: "/demos/list-boxes/selection-list" },
            ]
        },
        { id: DEMO_NAV_IDS.DEMOS_CLAUSES, name: "demos:clauses", label: "Clauses", path: "/demos/clauses",
            children: [
                { id: DEMO_NAV_IDS.DEMOS_CLAUSE_LIST_MANAGER, name: "demos:clauses:clause-list-manager", label: "Clause List Manager", path: "/demos/clauses/clause-list-manager" },
                { id: DEMO_NAV_IDS.DEMOS_DRAG_DROP_DEMO, name: "demos:clauses:drag-drop-demo", label: "Drag Drop Demo", path: "/demos/clauses/drag-drop-demo" },
            ]
        },
        { id: DEMO_NAV_IDS.DEMOS_VUE_3, name: "demos:vue-3", label: "Vue 3", path: "/demos/vue-3",
            children: [
                { id: DEMO_NAV_IDS.DEMOS_RQ_BUSY_COMPOSABLE, name: "demos:vue-3:rq-busy", label: "RQ Busy Composable", path: "/demos/vue-3/rq-busy-composable" }
            ]
        },
        { id: DEMO_NAV_IDS.DEMOS_WIDGETS, name: "demos:widgets", label: "Widgets", path: "/widgets/circle-gauge",
            children: [
                { id: DEMO_NAV_IDS.DEMOS_CIRCLE_GAUGE, name: "demos:widgets:circle-gauge", label: "Circle Gauge Widget", path: "/demos/widgets/circle-gauge" }
            ]
        },
        { id: DEMO_NAV_IDS.DEMOS_IMAGE_PREVIEW, name: "demos:image-preview", label: "Image Preview", path: "/demos/image-preview" },
        { id: DEMO_NAV_IDS.DEMOS_OFFCANVAS, name: "demos:offcanvas", label: "Offcanvas", path: "/demos/offcanvas" },
        { id: DEMO_NAV_IDS.DEMOS_TREEVIEW, name: "demos:treeview", label: "Tree View", path: "/demos/treeview" },
        { id: DEMO_NAV_IDS.DEMOS_REPORT_VIEWER, name: "demos:report-viewer", label: "Report Viewer", path: "/demos/report-viewer" },
        { id: DEMO_NAV_IDS.DEMOS_WIZARD_COMPONENT, name: "demos:wizard-component", label: "Wizard Component", path: "/demos/wizard-component" },
        { id: DEMO_NAV_IDS.DEMOS_MASTER_DETAIL_VIEW, name: "demos:master-detail-view", label: "Master-Detail View", path: "/demos/master-detail-view" },
        { id: DEMO_NAV_IDS.DEMOS_API_TESTER, name: "demos:api-tester", label: "API Tester", path: "/demos/api-tester" },
        { id: DEMO_NAV_IDS.DEMOS_TOAST_TESTER, name: "demos:toast-tester", label: "Toast Tester", path: "/demos/toast-tester" },
        { id: DEMO_NAV_IDS.DEMOS_INTEGRATION_TESTER, name: "demos:integration-tester", label: "Integration Tester", path: "/demos/integration-tester" },
        { id: DEMO_NAV_IDS.DEMOS_DIALOGS, name: "demos:dialogs", label: "Dialogs", path: "/demos/dialogs" },
    ]
};
