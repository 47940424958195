const BasicGridDemo = () => import(/* webpackChunkName: "demos" */"./views/Grids/BasicGridDemo.vue");
const EditableGridDemo = () => import(/* webpackChunkName: "demos" */"./views/Grids/EditableGridDemo.vue");
const ActionGridDemo = () => import(/* webpackChunkName: "demos" */"./views/Grids/ActionGridDemo.vue");
const DragAndDropGridDemo = () => import(/* webpackChunkName: "demos" */"./views/Grids/DragAndDropGridDemo.vue");
const DragDropMasterDetailDemo = () => import(/* webpackChunkName: "demos" */"./views/Grids/DragDropMasterDetailDemo.vue");
const TreeListDemo = () => import(/* webpackChunkName: "demos" */"./views/Grids/DxTreeListDemo.vue");
const FauxGridDemo = () => import(/* webpackChunkName: "demos" */"./views/Grids/FauxGridDemo.vue");

const RqDocumentEditorDemo = () => import(/* webpackChunkName: "demos" */"./views/Documents/RqDocumentEditorDemo.vue");
const ListyStylesDemo = () => import(/* webpackChunkName: "demos" */"./views/Documents/ListStyleFormats.vue");
// const MergeFieldSelectionDemo = () => import(/* webpackChunkName: "demos" */"./views/Documents/MergeFieldSelectionDemo.vue");
const RqTreeViewDemo = () => import(/* webpackChunkName: "demos" */"./views/Documents/RqTreeViewDemo.vue");
const DocumentUtilities = () => import(/* webpackChunkName: "demos" */"./views/Documents/DocumentUtilities.vue");
const PdfViewerDemo = () => import(/* webpackChunkName: "demos" */"./views/Documents/PdfViewerDemo.vue");
const HtmlEditorDemos = () => import(/* webpackChunkName: "demos" */"./views/Documents/HtmlEditors.vue");

const FormEditorExamples = () => import(/* webpackChunkName: "demos" */"./views/FormEditorExamples.vue");
const EmailTagBoxExample = () => import(/* webpackChunkName: "demos" */"./views/EmailTagBoxExample.vue");
const ImagePreviewDemo = () => import(/* webpackChunkName: "demos" */"./views/ImagePreviewDemo.vue");
const ListManagerDemo = () => import(/* webpackChunkName: "demos" */"./views/ListManagerDemo.vue");
const OffcanvasDemo = () => import(/* webpackChunkName: "demos" */"./views/OffcanvasDemo.vue");
const SelectionListDemo = () => import(/* webpackChunkName: "demos" */"./views/SelectionListDemo.vue");
const ValidationExamples = () => import(/* webpackChunkName: "demos" */"./views/ValidationExamples.vue");
const ReportViewerExamples = () => import(/* webpackChunkName: "demos" */"./views/ReportViewerExamples.vue");
const WizardComponentDemo = () => import(/* webpackChunkName: "demos" */"./views/WizardComponentDemo.vue");
const MasterDetailViewDemo = () => import(/* webpackChunkName: "demos" */"./views/layouts/MasterDetailViewDemo.vue");
const ApiTester = () => import(/* webpackChunkName: "demos" */"./views/ApiTester.vue");
const ToastTester = () => import(/* webpackChunkName: "demos" */"./views/ToastTester.vue");
const IntegrationTester = () => import(/* webpackChunkName: "demos" */"./views/IntegrationTester.vue");

const ClauseListDemo = () => import(/* webpackChunkName: "demos" */"./views/Clauses/ClauseListDemo.vue");
const DragDropDemo = () => import(/* webpackChunkName: "demos" */"./views/Clauses/DragDropDemo.vue");

const RqBusyComposableDemo = () => import(/* webpackChunkName: "demos" */"./views/vue3/RqBusyComposableDemo.vue");
const RqDialogDemos = () => import(/* webpackChunkName: "demos" */"./views/Dialogs/DialogDemos.vue");
const CircleGaugeWidget = () => import(/* webpackChunkName: "demos" */"./views/Widgets/CircleGaugeWidget.vue");

export default [
    {
        name: "demos:grids",
        path: "grids",
        meta: { label: "Grid Demos", include: true },
        redirect: "grids/action-grid",
        children: [
            {
                name: "demos:grids:basic-grid",
                path: "basic-grid",
                component: BasicGridDemo,
                meta: { label: "Action Grid", skipSaveOnLeave: true }
            },
            {
                name: "demos:grids:action-grid",
                path: "action-grid",
                component: ActionGridDemo,
                meta: { label: "Action Grid", skipSaveOnLeave: true }
            },
            {
                name: "demos:grids:editable-grid",
                path: "editable-grid",
                component: EditableGridDemo,
                meta: { label: "Editable Grid", skipSaveOnLeave: true }
            },
            {
                name: "demos:grids:drag-drop-grid",
                path: "drag-drop-grid",
                component: DragAndDropGridDemo,
                meta: { label: "Drag & Drop Grid", skipSaveOnLeave: true }
            },
            {
                name: "demos:grids:drag-drop-master-detail-grid",
                path: "drag-drop-master-detail-grid",
                component: DragDropMasterDetailDemo,
                meta: { label: "Drag & Drop Master-Detail Grid", skipSaveOnLeave: true }
            },
            {
                name: "demos:grids:faux-grid",
                path: "faux-grid",
                component: FauxGridDemo,
                meta: { label: "Faux Grid", skipSaveOnLeave: true }
            },
            {
                name: "demos:grids:tree-list",
                path: "tree-list",
                component: TreeListDemo,
                meta: { label: "Tree List", skipSaveOnLeave: true }
            },
        ]
    },
    {
        name: "demos:document-demos",
        path: "documents",
        redirect: "documents/editor",
        meta: { label: "Documents", include: true },
        children: [
            {
                name: "demos:docs:doc-util",
                path: "utilities",
                component: DocumentUtilities,
                meta: { label: "Document Utilities", skipSaveOnLeave: true }
            },
            {
                name: "demos:docs:rq-doc-editor",
                path: "rq-document-editor",
                component: RqDocumentEditorDemo,
                meta: { label: "Rq Document Editor Component", skipSaveOnLeave: true }
            },
            {
                name: "demos:docs:list-styles",
                path: "list-styles",
                component: ListyStylesDemo,
                meta: { label: "List Style Formats", skipSaveOnLeave: true }
            },
            {
                name: "demos:docs:pdf-viewer",
                path: "pdf-viewer",
                component: PdfViewerDemo,
                meta: { label: "PDF Viewer", skipSaveOnLeave: true }
            },
            {
                name: "demos:docs:html-editor-demos",
                path: "html-editors",
                component: HtmlEditorDemos,
                meta: { label: "Html Editors", include: true, skipSaveOnLeave: true }
            },
        ]
    },
    {
        name: "demos:forms",
        path: "forms",
        redirect: "lists/form-editors",
        meta: { label: "Forms", include: true },
        children: [
            {
                name: "demos:forms:form-editors",
                path: "form-editors",
                component: FormEditorExamples,
                meta: {
                    label: "Form Editors",
                    theme: "slate",
                    skipSaveOnLeave: true,

                    /*  Add any of the following settings to adjust the
                        RqScrollContainer back-to-top button (defaults shown):
                        ******************************************************

                        backToTop: {
                            position: "bottom", //"top" or "bottom"
                            offset: 0,          //positive or negative number in pixels
                            enabled: true       //self-explanatory
                        }
                    */
                }
            },
            {
                name: "demos:forms:validation",
                path: "validation",
                component: ValidationExamples,
                meta: { label: "Validation", skipSaveOnLeave: true }
            },
            {
                name: "demos:forms:email-tag-box",
                path: "email-tag-box",
                component: EmailTagBoxExample,
                meta: { label: "Email TagBox", skipSaveOnLeave: true }
            },
        ]
    },
    {
        name: "demos:list-demos",
        path: "list-boxes",
        redirect: "list-boxes/list-manager",
        meta: { label: "Lists", include: true },
        children: [
            {
                name: "demos:list-boxes:list-manager",
                path: "list-manager",
                component: ListManagerDemo,
                meta: { label: "RQ List Manager", skipSaveOnLeave: true }
            },
            {
                name: "demos:list-boxes:selection-list",
                path: "selection-list",
                component: SelectionListDemo,
                meta: { label: "RQ Selection List Box", skipSaveOnLeave: true }
            }
        ]
    },
    {
        name: "demos:clauses",
        path: "clauses",
        redirect: "clauses/clause-list-manager",
        meta: { label: "Clauses", include: true },
        children: [
            {
                name: "demos:clauses:clause-list-manager",
                path: "clause-list-manager",
                component: ClauseListDemo,
                meta: { label: "Clause List Manager", skipSaveOnLeave: true }
            },
            {
                name: "demos:clauses:drag-drop-demo",
                path: "drag-drop-demo",
                component: DragDropDemo,
                meta: { label: "Drag Drop Demo", skipSaveOnLeave: true }
            }
        ]
    },
    {
        name: "demos:vue-3",
        path: "vue-3",
        redirect: "vue-3/rq-busy-composable",
        meta: { label: "Vue 3 Demos", include: true },
        children: [
            {
                name: "demos:vue-3:rq-busy",
                path: "rq-busy-composable",
                component: RqBusyComposableDemo,
                meta: { label: "RQ Busy Composable", skipSaveOnLeave: true }
            }
        ]
    },
    {
        name: "demos:vue-3",
        path: "vue-3",
        redirect: "vue-3/rq-busy-composable",
        meta: { label: "Vue 3 Demos", include: true },
        children: [
            {
                name: "demos:vue-3:rq-busy",
                path: "rq-busy-composable",
                component: RqBusyComposableDemo,
                meta: { label: "RQ Busy Composable", skipSaveOnLeave: true }
            }
        ]
    },
    {
        name: "demos:widgets",
        path: "widgets",
        redirect: "widgets/circle-gauge",
        meta: { label: "Widgets", include: true },
        children: [
            {
                name: "demos:widgets:circle-gauge",
                path: "circle-gauge",
                component: CircleGaugeWidget,
                meta: { label: "Circle Gauge Widget", skipSaveOnLeave: true }
            }
        ]
    },
    {
        name: "demos:treeview",
        path: "treeview",
        component: RqTreeViewDemo,
        meta: { label: "RQ TreeView Demo", skipSaveOnLeave: true }
    },
    {
        name: "demos:image-preview",
        path: "image-preview",
        component: ImagePreviewDemo,
        meta: { label: "Image Preview", skipSaveOnLeave: true }
    },
    {
        name: "demos:offcanvas",
        path: "offcanvas",
        component: OffcanvasDemo,
        meta: { label: "Offcanvas", skipSaveOnLeave: true }
    },
    {
        name: "demos:report-viewer",
        path: "report-viewer",
        component: ReportViewerExamples,
        meta: { label: "Report Viewer", skipSaveOnLeave: true }
    },
    {
        name: "demos:wizard-component",
        path: "wizard-component",
        component: WizardComponentDemo,
        meta: { label: "Wizard Component Demo", skipSaveOnLeave: true }
    },
    {
        name: "demos:master-detail-view",
        path: "master-detail-view",
        component: MasterDetailViewDemo,
        meta: { label: "Master-Detail View Demo", skipSaveOnLeave: true }
    },
    {
        name: "demos:api-tester",
        path: "api-tester",
        component: ApiTester,
        meta: { label: "API Tester", skipSaveOnLeave: true }
    },
    {
        name: "demos:toast-tester",
        path: "toast-tester",
        component: ToastTester,
        meta: { label: "Toast Tester", skipSaveOnLeave: true }
    },
    {
        name: "demos:integration-tester",
        path: "integration-tester",
        component: IntegrationTester,
        meta: { label: "Integration Tester", skipSaveOnLeave: true }
    },
    {
        name: "demos:dialogs",
        path: "dialogs",
        component: RqDialogDemos,
        meta: { label: "Dialogs", skipSaveOnLeave: true }
    },
];
